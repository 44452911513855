<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height"
		viewBox="0 0 20.323 11.367">
		<g id="eye_1_" data-name="eye (1)" transform="translate(0.806 0.75)" opacity="0.6">
			<g id="Сгруппировать_22578" data-name="Сгруппировать 22578" transform="translate(0)">
				<g id="Сгруппировать_22577" data-name="Сгруппировать 22577"
				transform="translate(0)">
					<path id="Контур_553" data-name="Контур 553"
						d="M18.571,125.568C18.4,125.382,14.386,121,9.355,121S.306,125.382.139,125.568a.548.548,0,0,0,0,.731c.167.186,4.184,4.568,9.216,4.568s9.049-4.382,9.216-4.568A.548.548,0,0,0,18.571,125.568Z"
						transform="translate(0.001 -121)" fill="none" stroke="#fff"
						stroke-width="1.5"/>
				</g>
			</g>
			<g id="Сгруппировать_22580" data-name="Сгруппировать 22580"
			transform="translate(6.615 2.193)">
				<g id="Сгруппировать_22579" data-name="Сгруппировать 22579"
				transform="translate(0 0)">
					<path id="Контур_554" data-name="Контур 554"
						d="M184.289,182.645a1.639,1.639,0,0,1,.69-1.335,2.705,2.705,0,0,0-1.238-.31,2.741,2.741,0,1,0,2.694,3.2A1.635,1.635,0,0,1,184.289,182.645Z"
						transform="translate(-181 -181)" fill="#fff"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	name: "EyeIcon",
	props: {
		height: {
			type: Number,
			default: 24
		},
		width: {
			type: Number,
			default: 24
		},
        color: {
            type: String,
            default: '#ffffff'
        },
  	}
}
</script>
