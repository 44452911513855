<template>
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
		<g>
			<path d="M444.176 0 183.305 242.012 72.98 124.277 0 192.664l178.234 190.207L512 73.52zm0 0" :fill="color" opacity="1" data-original="#000000" class=""></path>
		</g>
	</svg>
</template>

<script>
export default {
	name: "CheckIcon",
	props: {
		height: {
			type: Number,
			default: 24
		},
		width: {
			type: Number,
			default: 24
		},
        color: {
            type: String,
            default: '#ffffff'
        }
  	}
}
</script>
