<template>
	<div>
		<svg v-if="mute" :width="width" :height="height" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M166.667 166.667L633.333 633.333" stroke="white" stroke-width="66.6667" stroke-linecap="round" stroke-linejoin="round"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M266.762 230.57L221.641 266.667H133.333C96.5144 266.667 66.6667 296.515 66.6667 333.333V466.666C66.6667 503.486 96.5144 533.333 133.333 533.333H221.641L325.02 616.036C368.67 650.96 433.333 619.88 433.333 563.98V397.14L366.667 330.475V563.98L263.287 481.276C251.466 471.82 236.779 466.666 221.641 466.666H133.333V333.333H221.641C236.779 333.333 251.466 328.182 263.287 318.726L314.193 278.001L266.762 230.57ZM366.667 236.194V236.022L366.57 236.098L319.141 188.668L325.02 183.964C368.67 149.043 433.333 180.121 433.333 236.022V302.861L366.667 236.194ZM510.623 474.433C508.007 478.323 505.177 482.087 502.14 485.707C490.307 499.81 492.147 520.833 506.247 532.666C520.35 544.5 541.377 542.66 553.21 528.56C554.97 526.46 556.687 524.326 558.357 522.163L510.623 474.433ZM590.713 460.24L533.303 402.83C533.913 374.05 525.193 345.823 508.413 322.385C497.697 307.416 501.143 286.594 516.113 275.877C531.08 265.161 551.903 268.608 562.62 283.577C588.457 319.667 601.557 363.326 599.853 407.68C599.163 425.646 596.06 443.317 590.713 460.24ZM605.787 569.596C605.287 570.203 604.783 570.806 604.28 571.41C592.447 585.513 594.283 606.54 608.387 618.373C621.597 629.457 640.877 628.543 652.99 616.8L605.787 569.596ZM691.733 561.26L642.153 511.68C657.93 477.476 666.393 440.066 666.66 401.863C667.093 339.81 645.873 279.548 606.653 231.46C595.017 217.193 597.15 196.196 611.417 184.561C625.683 172.925 646.68 175.058 658.313 189.324C707.34 249.435 733.867 324.762 733.327 402.327C732.933 458.283 718.477 512.876 691.733 561.26Z" :fill="color"/>
		</svg>

		<svg v-else :width="width" :height="height" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M100 466.667V333.333C100 314.924 114.924 300 133.333 300H221.641C229.21 300 236.553 297.424 242.464 292.696L345.843 209.992C367.67 192.532 400 208.071 400 236.021V563.98C400 591.93 367.67 607.47 345.843 590.007L242.464 507.303C236.553 502.577 229.21 500 221.641 500H133.333C114.924 500 100 485.077 100 466.667Z" stroke="white" stroke-width="66.6667" stroke-linecap="round" stroke-linejoin="round"/>

			<path d="M527.673 507.13C551.45 478.796 565.123 443.36 566.543 406.397C567.963 369.437 557.047 333.054 535.517 302.979" stroke="white" stroke-width="66.6667" stroke-linecap="round" stroke-linejoin="round"/>

			<path d="M629.813 592.836C674.687 539.356 699.507 471.903 699.993 402.093C700.48 332.285 676.607 264.491 632.483 210.391" stroke="white" stroke-width="66.6667" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	</div>
</template>

<script>
export default {
	name: "VolumeIcon",
	props: {
		height: {
			type: Number,
			default: 24
		},
		width: {
			type: Number,
			default: 24
		},
        color: {
            type: String,
            default: '#ffffff'
        },
		mute: {
			type: Boolean,
			default: false
		}
  	}
}
</script>
