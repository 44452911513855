<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 22.003 19.724">
		<g id="Сгруппировать_28785" data-name="Сгруппировать 28785" transform="translate(0.149 0.125)">
			<g id="Сгруппировать_28784" data-name="Сгруппировать 28784" transform="translate(0)">
				<path id="Контур_34841" data-name="Контур 34841" d="M21.359,41.961l-8.3-14.37a2.552,2.552,0,0,0-4.421,0l-8.3,14.37a2.552,2.552,0,0,0,2.21,3.828H19.149a2.552,2.552,0,0,0,2.21-3.828Zm-1.1,1.917a1.268,1.268,0,0,1-1.109.64H2.556A1.281,1.281,0,0,1,1.447,42.6l8.3-14.37a1.281,1.281,0,0,1,2.218,0l8.3,14.37A1.268,1.268,0,0,1,20.258,43.878Z" transform="translate(0 -26.315)" fill="#fff" stroke="#fff" stroke-width="0.25"/>
			</g>
		</g>
		<g id="Сгруппировать_28787" data-name="Сгруппировать 28787" transform="translate(10.366 6.471)">
			<g id="Сгруппировать_28786" data-name="Сгруппировать 28786" transform="translate(0 0)">
				<rect id="Прямоугольник_5257" data-name="Прямоугольник 5257" width="1.272" height="6.358" fill="#fff" stroke="#fff" stroke-width="0.25"/>
			</g>
		</g>
		<g id="Сгруппировать_28789" data-name="Сгруппировать 28789" transform="translate(10.154 14.101)">
			<g id="Сгруппировать_28788" data-name="Сгруппировать 28788">
				<path id="Контур_34842" data-name="Контур 34842" d="M236.85,355.99a.848.848,0,1,0,.848.848A.849.849,0,0,0,236.85,355.99Z" transform="translate(-236.002 -355.99)" fill="#fff" stroke="#fff" stroke-width="0.25"/>
			</g>
		</g>
	</svg>

</template>

<script>
export default {
	name: "ComplainIcon",
	props: {
		height: {
			type: Number,
			default: 24
		},
		width: {
			type: Number,
			default: 24
		},
        color: {
            type: String,
            default: '#ffffff'
        },
  	}
}
</script>
