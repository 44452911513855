<template>
    <app-transition>
        <Dialog
            :only-text="true"
            @close="$emit('close')"
            class="add-stream-goal-dialog" >
            <v-row>
                <h2>{{ $t('dialogs.stream_goals.form_title') }}</h2>
            </v-row>
            <v-form ref="form" class="mt-5 w-full px-5">
                <v-row>
                    <v-text-field
                        solo
                        outlined
                        background-color="rgba(237,237,237, .1)"
                        :label="$t('dialogs.stream_goals.goal_name')"
                        v-model="model.goal_name"
                        :rules="name_rules"
                    />
                </v-row>
                <v-row>
                    <v-text-field
                        solo
                        outlined
                        background-color="rgba(237,237,237, .1)"
                        :label="$t('dialogs.stream_goals.goal_price')"
                        v-model="model.goal_price"
                        :rules="price_rules"
                    >
                        <template #prepend-inner>
                            <span style="color: white;">{{ currency }}</span>
                        </template>
                    </v-text-field>
                </v-row>
                <v-row justify="center">
                    <v-btn outlined block @click="$emit('save-goal', model)">{{ $t('dialogs.stream_goals.add_goal') }}</v-btn>
                </v-row>
            </v-form>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog";
import {CURRENCY_SYMBOL} from "@/configs/constants";

export default {
    name: "AddStreamGoalDialog",

    data: () => ({
        model: {
            goal_name: null,
            goal_price: null,
        },
        currency: CURRENCY_SYMBOL,
        name_rules: [
            (v) => !!v || 'The goal name field is required',
            (v) => (v && v.length <= 20) || "Goal name should not be more than 20 Characters",
        ],
        price_rules: [
            (v) => !!v || 'The goal price field is required',
        ],
    }),

    components: {
        Dialog,
    }
}
</script>
